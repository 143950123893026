/* src/App.svelte generated by Svelte v3.46.2 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_input_value,
	space,
	svg_element,
	text
} from "svelte/internal";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	return child_ctx;
}

// (90:2) {#each levels as level}
function create_each_block(ctx) {
	let div;
	let a0;
	let t0;
	let t1_value = /*level*/ ctx[4] + "";
	let t1;
	let a0_class_value;
	let a0_href_value;
	let t2;
	let a1;
	let t3;
	let t4_value = /*level*/ ctx[4] + "";
	let t4;
	let a1_class_value;
	let a1_href_value;
	let t5;

	return {
		c() {
			div = element("div");
			a0 = element("a");
			t0 = text("Get your input to level ");
			t1 = text(t1_value);
			t2 = space();
			a1 = element("a");
			t3 = text("Get your answer to level ");
			t4 = text(t4_value);
			t5 = space();
			attr(a0, "class", a0_class_value = "btn " + (Boolean(/*ghUsername*/ ctx[1]) ? 'green' : 'gray') + " svelte-mxi4uw");

			attr(a0, "href", a0_href_value = Boolean(/*ghUsername*/ ctx[1])
			? `${/*baseURL*/ ctx[0]}/generate/${/*ghUsername*/ ctx[1]}/${/*level*/ ctx[4]}`
			: undefined);

			attr(a0, "target", "_blank");
			attr(a1, "class", a1_class_value = "btn " + (Boolean(/*ghUsername*/ ctx[1]) ? 'blue' : 'gray') + " svelte-mxi4uw");

			attr(a1, "href", a1_href_value = Boolean(/*ghUsername*/ ctx[1])
			? `${/*baseURL*/ ctx[0]}/solve/${/*ghUsername*/ ctx[1]}/${/*level*/ ctx[4]}`
			: undefined);

			attr(a1, "target", "_blank");
			attr(div, "id", "getButtons");
			attr(div, "class", "svelte-mxi4uw");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, a0);
			append(a0, t0);
			append(a0, t1);
			append(div, t2);
			append(div, a1);
			append(a1, t3);
			append(a1, t4);
			append(div, t5);
		},
		p(ctx, dirty) {
			if (dirty & /*ghUsername*/ 2 && a0_class_value !== (a0_class_value = "btn " + (Boolean(/*ghUsername*/ ctx[1]) ? 'green' : 'gray') + " svelte-mxi4uw")) {
				attr(a0, "class", a0_class_value);
			}

			if (dirty & /*ghUsername, baseURL*/ 3 && a0_href_value !== (a0_href_value = Boolean(/*ghUsername*/ ctx[1])
			? `${/*baseURL*/ ctx[0]}/generate/${/*ghUsername*/ ctx[1]}/${/*level*/ ctx[4]}`
			: undefined)) {
				attr(a0, "href", a0_href_value);
			}

			if (dirty & /*ghUsername*/ 2 && a1_class_value !== (a1_class_value = "btn " + (Boolean(/*ghUsername*/ ctx[1]) ? 'blue' : 'gray') + " svelte-mxi4uw")) {
				attr(a1, "class", a1_class_value);
			}

			if (dirty & /*ghUsername, baseURL*/ 3 && a1_href_value !== (a1_href_value = Boolean(/*ghUsername*/ ctx[1])
			? `${/*baseURL*/ ctx[0]}/solve/${/*ghUsername*/ ctx[1]}/${/*level*/ ctx[4]}`
			: undefined)) {
				attr(a1, "href", a1_href_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let svg;
	let defs;
	let style;
	let t0;
	let title;
	let t1;
	let g1;
	let g0;
	let path0;
	let path1;
	let path2;
	let path3;
	let t2;
	let h1;
	let t4;
	let p0;
	let t6;
	let p1;
	let t8;
	let input;
	let t9;
	let p2;
	let t10;
	let mounted;
	let dispose;
	let each_value = /*levels*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div = element("div");
			svg = svg_element("svg");
			defs = svg_element("defs");
			style = svg_element("style");
			t0 = text(".e7532d7f-8b0a-4a7e-b147-f37ae1f1b97b {\n          fill-opacity: 0;\n          stroke: #20c20e;\n          stroke-miterlimit: 10;\n          stroke-width: 5.99px;\n        }\n      ");
			title = svg_element("title");
			t1 = text("Asset 1");
			g1 = svg_element("g");
			g0 = svg_element("g");
			path0 = svg_element("path");
			path1 = svg_element("path");
			path2 = svg_element("path");
			path3 = svg_element("path");
			t2 = space();
			h1 = element("h1");
			h1.textContent = "VandyHacks Dev Team Assessment";
			t4 = space();
			p0 = element("p");
			p0.textContent = "Here's where you'll get your inputs for the VandyHacks dev assessment. Please enter your GitHub\n    username below.";
			t6 = space();
			p1 = element("p");

			p1.innerHTML = `<strong class="svelte-mxi4uw">Make sure you type your GitHub username correctly otherwise your assessment will be assessed
      against a different input.</strong>`;

			t8 = space();
			input = element("input");
			t9 = space();
			p2 = element("p");
			t10 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(style, "class", "svelte-mxi4uw");
			attr(defs, "class", "svelte-mxi4uw");
			attr(title, "class", "svelte-mxi4uw");
			attr(path0, "class", "e7532d7f-8b0a-4a7e-b147-f37ae1f1b97b svelte-mxi4uw");
			attr(path0, "d", "M252.14,3h-18A7.18,7.18,0,0,0,228,7.74L152.23,231.05l-76-223.3A7.2,7.2,0,0,0,70,3H52A3.08,3.08,0,0,0,48.7,5.9,3.54,3.54,0,0,0,49,7.75l88.87,261.16a6.59,6.59,0,0,0,.9,1.7l-9.58,28.26L30.51,7.76A7.14,7.14,0,0,0,24.29,3h-18A3.07,3.07,0,0,0,3,5.94a3.53,3.53,0,0,0,.3,1.82L114.56,335.83a7,7,0,0,0,1.88,2.8,3,3,0,0,0,3.15,2.27h18a5.44,5.44,0,0,0,2.06-.44,3,3,0,0,0,1.6-.9,7.29,7.29,0,0,0,2.56-3.4L255.15,7.75a3.42,3.42,0,0,0-1.32-4.4A2.78,2.78,0,0,0,252.14,3Z");
			attr(path1, "class", "e7532d7f-8b0a-4a7e-b147-f37ae1f1b97b svelte-mxi4uw");
			attr(path1, "d", "M344.82,3h-18a7.18,7.18,0,0,0-6.22,4.73L209.26,336.25a3.43,3.43,0,0,0,1.34,4.4,2.8,2.8,0,0,0,1.67.32h18a7.17,7.17,0,0,0,6.22-4.72L347.81,7.75a3.42,3.42,0,0,0-1.33-4.4A2.76,2.76,0,0,0,344.82,3Z");
			attr(path2, "class", "e7532d7f-8b0a-4a7e-b147-f37ae1f1b97b svelte-mxi4uw");
			attr(path2, "d", "M233.68,193.09h-18a7.18,7.18,0,0,0-6.22,4.73L162.57,336.25a3.41,3.41,0,0,0,1.32,4.39,2.76,2.76,0,0,0,1.66.33h18a7.15,7.15,0,0,0,6.14-4.61l47-138.58a3.41,3.41,0,0,0-1.37-4.38A2.82,2.82,0,0,0,233.68,193.09Z");
			attr(path3, "class", "e7532d7f-8b0a-4a7e-b147-f37ae1f1b97b svelte-mxi4uw");
			attr(path3, "d", "M222.71,172h18a7.18,7.18,0,0,0,6.22-4.73L301,7.63c.75-2.21-.1-4-1.95-4.57a2.8,2.8,0,0,0-.41,0H279.59a4.88,4.88,0,0,0-1.46.26,7.65,7.65,0,0,0-3.22,2.43,6.32,6.32,0,0,0-.76,1.46L219.48,168.44A3.05,3.05,0,0,0,222.71,172Z");
			attr(g0, "id", "b42df80a-f385-401a-a9e8-febce0a2c5c2");
			attr(g0, "data-name", "vh top");
			attr(g0, "class", "svelte-mxi4uw");
			attr(g1, "id", "b31ab2b5-9986-4a69-b92e-29055fb6d7a3");
			attr(g1, "data-name", "Layer 2");
			attr(g1, "class", "svelte-mxi4uw");
			attr(svg, "viewBox", "0 0 351.13 343.99");
			attr(svg, "class", "svelte-mxi4uw");
			attr(h1, "class", "svelte-mxi4uw");
			attr(p0, "class", "svelte-mxi4uw");
			attr(p1, "class", "svelte-mxi4uw");
			attr(input, "placeholder", "your github username");
			attr(input, "class", "svelte-mxi4uw");
			attr(p2, "class", "svelte-mxi4uw");
			attr(div, "id", "app");
			attr(div, "class", "svelte-mxi4uw");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, svg);
			append(svg, defs);
			append(defs, style);
			append(style, t0);
			append(svg, title);
			append(title, t1);
			append(svg, g1);
			append(g1, g0);
			append(g0, path0);
			append(g0, path1);
			append(g0, path2);
			append(g0, path3);
			append(div, t2);
			append(div, h1);
			append(div, t4);
			append(div, p0);
			append(div, t6);
			append(div, p1);
			append(div, t8);
			append(div, input);
			set_input_value(input, /*ghUsername*/ ctx[1]);
			append(div, t9);
			append(div, p2);
			append(div, t10);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			if (!mounted) {
				dispose = listen(input, "input", /*input_input_handler*/ ctx[3]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*ghUsername*/ 2 && input.value !== /*ghUsername*/ ctx[1]) {
				set_input_value(input, /*ghUsername*/ ctx[1]);
			}

			if (dirty & /*Boolean, ghUsername, baseURL, levels, undefined*/ 7) {
				each_value = /*levels*/ ctx[2];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { baseURL } = $$props;
	const levels = [0, 1, 2, 3];
	let ghUsername;

	function input_input_handler() {
		ghUsername = this.value;
		$$invalidate(1, ghUsername);
	}

	$$self.$$set = $$props => {
		if ('baseURL' in $$props) $$invalidate(0, baseURL = $$props.baseURL);
	};

	return [baseURL, ghUsername, levels, input_input_handler];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { baseURL: 0 });
	}
}

export default App;